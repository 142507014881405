:active, :focus, :hover {
    outline: 0 !important;
    outline-offset: 0 !important
}

a {
    color: #043d7b94
}

a:hover {
    color: #043d7b94
}

body {
    background: #fafafa
}

.nav__wrp-menu .navbar-menu {
    background: #fafafa !important
}

.custom-control-input:checked ~ .custom-control-label:before {
    color: #fff;
    border-color: #043d7b94 !important;
    background-color: #043d7b94
}

.custom-control-input:focus ~ .custom-control-label:before {
    -webkit-box-shadow: 0 0 0 0.2rem #C8E9FF;
    box-shadow: 0 0 0 0.2rem #C8E9FF
}

.form-control {
    color: #103173;
    border-radius: 0
}

.form-control_bg {
    background: transparent;
    border-color: #3174dd;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(../img/arrow_icon.png) no-repeat right;
    background-position-x: calc(100% - 8px)
}

.form-control_bg:focus {
    background: transparent url(../img/arrow_icon.png) no-repeat right;
    background-position-x: calc(100% - 8px);
    border-color: #3174dd
}

.custom-control .custom-control-label {
    color: #103173 !important
}

.general__card-serch {
    margin-top: 10px;
    background: #d9e4f6;
    border: 0;
    border-radius: 0
}

.general__checkbox {
    margin-bottom: 8px
}

.general__text-form {
    line-height: 38px
}

.general__navbar {
    margin-bottom: 20px;
    position: relative;
    margin-top: 10px;
    background: #d9e4f6 !important;
    color: #103173 !important;
    padding-right: 10px;
    padding-left: 10px
}

.general__navbar ul li {
    min-width: 100px;
    color: #103173 !important
}

.general__nav {
    margin-top: 30px
}

.general__nav .dropdown {
    display: none
}

.general__btn {
    text-transform: uppercase;
    border: 0;
    background: #3174dd;
    color: #fff;
    border-radius: 0
}

.general__btn:hover {
    background: #579AFF
}

.general__btn:active {
    background: #043d7bb0 !important
}

.general__btn:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important
}

.general__content {
    min-height: 450px;
    background: #fafafa url(../img/bg_1.png) no-repeat center top;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center 50px
}

.general__content_all {
    min-height: calc(100vh - 98px)
}

.general__col-pd {
    padding: 0
}

.general__content_none-bg {
    background: #fafafa
}

.persone-account {
    position: relative
}

.persone-account .name {
    color: #103173;
    display: inline-block;
    position: absolute;
    left: calc(50% - 30px);
    bottom: -24px;
    font-size: 14px;
    width: 60px
}

.bread-crumbs {
    padding-top: 3px
}

.bread-crumbs ul {
    padding: 0;
    list-style-type: none
}

.bread-crumbs ul li {
    float: left;
    margin-right: 10px
}

.bread-crumbs ul li a {
    color: #c0c0c0
}

.bread-crumbs ul li a:hover {
    text-decoration: none
}

.bread-crumbs ul li.arrow {
    color: #3174dd
}

.bread-crumbs ul li.active a {
    color: #3174dd
}

.nav__title {
    color: #103173 !important;
    width: 56px;
    text-align: center;
    position: absolute;
    line-height: 35px;
    left: calc(50% - 28px);
    top: 0.5rem
}

.nav__message_wrp {
    overflow: hidden
}

.nav__wrp-menu {
    min-height: 68px;
    position: fixed;
    width: 100%;
    z-index: 99;
    background: #fafafa
}

.nav__wrp-menu .navbar-menu {
    padding-right: 0;
    padding-left: 0
}

.nav__wrp-menu.sidenav {
    position: fixed
}

.nav__wrp-menu.sidenav .nav__message-wrp {
    overflow: hidden;
    height: 0 !important;
    padding-bottom: 0 !important
}

.nav__padding {
    height: 68px
}

.nav__row {
    display: block;
    width: 100%;
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden
}

.nav__logo-under {
    font-size: 14px
}

.nav__message-wrp {
    display: block;
    top: 0;
    position: relative;
    -webkit-transition: top 0.3s;
    -o-transition: top 0.3s;
    transition: top 0.3s;
    left: -10%;
    width: 120%;
    background: #3174DD;
    padding-bottom: 35px;
    border-radius: 0 0 100% 100% / 0 0 100% 100%
}

.nav__message-paragraph {
    padding-top: 15px;
    color: #fff;
    font-size: 14px;
    margin-bottom: 0
}

.nav__row-scrollbar {
    padding-right: 8px;
    padding-left: 8px
}

.nav__row::-webkit-scrollbar {
    width: 8px;
    background-color: #F5F5F5;
    border-radius: 6px
}

.nav__row::-webkit-scrollbar-thumb {
    border-radius: 6px;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
    background-color: #3174dd
}

.form-group label {
    width: 100%;
    text-align: center;
    color: #103173 !important
}

.form-group select {
    padding-right: 28px;
    font-size: 0.75rem
}

.form-group .form-control-one {
    padding-right: 0;
    padding-left: 0;
    text-align: center;
    width: 38px;
    margin-right: auto;
    margin-left: auto
}

.form-group .form-control-one {
    -moz-appearance: textfield
}

.form-group .form-control-one::-webkit-inner-spin-button {
    display: none
}

.form-group.error {
    position: relative
}

.form-group.error .form-control {
    border-color: #F61D15
}

.form-group.error .error-text {
    display: inline-block;
    font-size: 14px;
    position: absolute;
    bottom: -20px;
    left: 0;
    color: #F61D15
}

.form-group .error-text {
    display: none
}

.navbar__title-filter {
    padding-top: 5px;
    padding-bottom: 5px;
    color: #103173;
    margin-top: 20px;
    text-align: center;
    display: inline-block;
    width: 100%;
    border-top: 1px solid #3174dd;
    border-bottom: 1px solid #3174dd
}

.navbar__title-filter_next {
    margin-top: 10px
}

.navbar__links {
    float: right
}

.navbar__links .dropdown-menu {
    top: 90%;
    border-radius: 0;
    padding: 15px;
    min-width: 200px
}

.navbar__links .dropdown-menu__top {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.navbar__links .dropdown-menu__top .text-name {
    color: #103173;
    font-weight: bold
}

.navbar__links .dropdown-menu__top .sing {
    color: #3174dd !important
}

.navbar__links .dropdown-menu .login__btn {
    text-transform: none;
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 20px
}

.navbar__links .dropdown-menu .login__btn:last-child {
    margin-bottom: 0
}

.navbar__links a.link-menu {
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 10px;
    color: #c0c0c0
}

.navbar__links a.active {
    color: #3174dd !important
}

.navbar__logo {
    width: 30px;
    position: absolute;
    top: -6px
}

.navbar__text-logo {
    padding-left: 38px
}

.navbar__text-logo span {
    font-size: 14px
}

.navbar-menu {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.navbar-brand {
    position: relative;
    display: inline-block;
    line-height: 15px;
    font-size: 30px;
    color: #3174dd !important
}

.icon-persone {
    fill: #3174dd
}

.icon-filter {
    fill: #103173
}

.navbar_login {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: #fafafa !important
}

.nav__wrp-menu_logo {
    background: #fafafa
}

.card-result {
    background: #f2f2f2;
    padding: 20px;
    margin-bottom: 20px;
    position: relative
}

.card-result .card-body {
    width: 100%;
    background: #d9e4f6;
    border: 0;
    border-radius: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: row;
    -webkit-align-items: row;
    -ms-flex-align: row;
    align-items: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 14px
}

.card-result .card-body .title {
    font-size: 16px;
    font-weight: bold
}

.card-result .card-body .title-2 {
    text-align: center;
    font-size: 26px;
    margin-bottom: 10px;
    color: #103173
}

.card-result .card-body .text {
    margin-bottom: 20px
}

.card-result .card-body .text .link {
    text-decoration: underline;
    color: #007bff
}

.card-result .card-body .text .link:hover {
    text-decoration: none;
    color: #0056b3
}

.card-result .card-body .table {
    margin-top: 10px
}

.card-result .card-body .table__line {
    padding-left: 30px;
    width: 100%;
    display: inline-block
}

.card-result .card-body .table__line .value {
    display: inline-block;
    text-decoration: underline;
    width: 150px;
    color: #007bff
}

.card-result .card-body .table__line .value:hover {
    text-decoration: none;
    color: #0056b3
}

.card-result .card-body .img img {
    margin-bottom: 10px
}

.card-result .card-body .wrp-card {
    max-width: 280px;
    width: 100%;
    margin-right: auto;
    margin-left: auto
}

.card-result .card-body .wrp-card .text-left {
    font-size: 16px;
    color: #103173;
    margin-bottom: 8px;
    position: relative;
    padding-left: 20px
}

.card-result .card-body .wrp-card .text-left:after {
    content: "\2713";
    color: #103173;
    position: absolute;
    top: 0;
    left: 0
}

.card-result .card-body .wrp-card .text-center {
    font-size: 16px;
    color: #103173;
    text-align: center;
    margin-bottom: 0
}

.card-result .card-body .wrp-card .text-center .big {
    font-size: 25px;
    font-weight: bold
}

.card-result__text {
    min-height: 45px;
    margin-bottom: 0
}

.card-result .general__btn {
    margin-top: 15px;
    display: block;
    color: #fff !important
}

.card-result .general__btn:hover {
    cursor: pointer
}

.card-result__ul {
    list-style: none;
    padding: 0;
    margin: 0;
    overflow: hidden;
    position: relative;
    top: -10px;
    max-width: 360px
}

.card-result__ul li {
    float: left;
    margin-right: 2px;
    margin-left: 2px;
    font-size: 12px;
    color: #c0c0c0
}

.card-result__ul li a {
    font-size: 12px;
    color: #c0c0c0;
    text-decoration: none
}

.card-result__ul li.arrow {
    color: #c0c0c0
}

.card-result__ul li.active {
    color: #c0c0c0
}

.card__wrp-btn {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: row;
    -webkit-align-items: row;
    -ms-flex-align: row;
    align-items: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center
}

.card-result__btn-open {
    color: #103173;
    font-weight: bold;
    padding-left: 40px;
    position: relative;
    line-height: 25px;
    display: inline-block
}

.card-result__btn-open .code {
    font-size: 12px;
    position: relative;
    top: -8px
}

.card-result__btn-open:hover {
    color: #103173;
    text-decoration: none
}

.card-result__btn-open:after {
    content: '';
    position: absolute;
    display: block;
    top: 14px;
    left: 8px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 10px 6px;
    border-color: transparent transparent #3174dd transparent
}

.card-result__btn-open.collapsed:after {
    top: 16px;
    border-width: 10px 6px 0 6px;
    border-color: #3174dd transparent transparent transparent
}

.card-result__btn-open:before {
    content: '';
    position: absolute;
    display: block;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #fff;
    left: 0;
    top: 5px
}

.card__text-group {
    width: calc(100% - 360px);
    float: right;
    position: absolute;
    top: 20px;
    right: 20px
}

.card__text-text {
    display: block;
    width: 100%;
    font-size: 12px;
    color: #103173
}

.card__text-text .title {
    width: 70px;
    display: inline-block;
    font-size: 14px
}

.col-input-wrp {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-right: 5px;
    padding-left: 5px
}

.col-input-4 {
    width: 25%
}

.text-prev {
    font-size: 14px;
    color: #212529;
    display: inline-block;
    overflow: hidden;
    height: 62px;
    width: 100%;
    position: relative;
    top: -5px
}

.title-price {
    font-size: 1rem;
    color: #103173;
    font-weight: bold;
    margin-bottom: 22px;
    display: inline-block
}

.chekcbox-wrp {
    width: 110px;
    height: 25px;
    margin-top: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center
}

.chekcbox-wrp img {
    width: 25px
}

.icon-eco {
    width: 25px;
    position: absolute;
    top: 20px;
    right: 20px;
    fill: #103173
}

.search__title {
    margin-top: 30px;
    margin-left: 52%;
    line-height: 55px
}

.estimation__top {
    margin-top: 10px;
    margin-bottom: 10px;
    background: #d9e4f6;
    padding: 15px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.estimation__top .form-group {
    min-width: 200px;
    margin-bottom: 0
}

.estimation__top-title {
    font-size: 20px;
    font-weight: bold;
    color: #103173;
    margin-bottom: 0
}

.estimation__card {
    background: #d9e4f6;
    padding: 15px;
    margin-bottom: 20px
}

.estimation__card .form-group {
    margin-bottom: 0
}

.estimation__card_2 .estimation__input-text {
    text-transform: uppercase;
    line-height: 25px
}

.estimation__card_2 .form-group {
    height: 25px;
    width: calc(100% - 10px);
    float: left
}

.estimation__card_2 .form-control {
    height: 25px
}

.estimation__card_2 .estimation__input-text_money {
    width: 10px;
    float: left
}

.estimation__card_2 .col-7 {
    padding-right: 0
}

.estimation__input-text {
    line-height: 38px;
    color: #103173;
    text-align: right;
    margin-bottom: 10px;
    font-size: 15px
}

.estimation__input-text_cetner {
    text-align: center
}

.estimation__total {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 40px
}

.estimation__total .estimation__input-text {
    font-size: 16px;
    font-weight: bold
}

.login {
    min-height: calc(100vh - 98px);
    background: url(../img/bg_login.jpg) no-repeat center center;
    -webkit-background-size: 100% 100%;
    background-size: 100%
}

.login-card {
    margin-bottom: 40px;
    margin-top: 10vh;
    max-width: 440px;
    width: 100%;
    background: #d9e4f6;
    margin-right: auto;
    margin-left: auto;
    padding: 15px
}

.login__title {
    text-align: center;
    color: #103173;
    text-transform: uppercase;
    font-size: 20px;
    margin-bottom: 10px
}

.login__text-input {
    line-height: 30px;
    color: #103173;
    text-align: right
}

.login .col-5 {
    padding-right: 0
}

.login .col-sm-4 {
    padding-right: 0
}

.login .form-group {
    height: 30px
}

.login .form-group .error {
    font-size: 14px;
    display: none;
    color: red;
    position: relative;
    top: -2px
}

.login .form-group.error {
    height: 35px
}

.login .form-group.error .error {
    display: inline-block
}

.login .form-group .form-control {
    height: 30px;
    border: 0
}

.login__buttons {
    margin-top: 10px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center
}

.login__btn {
    background: #3174dd;
    color: #fff;
    padding: 4px 10px
}

.login__btn_login {
    background: #fff;
    color: #3174dd;
    border: 1px solid #3174dd;
    padding: 3px 10px
}

.login__btn:hover {
    text-decoration: none;
    background: #579AFF;
    color: #fff
}

.login__text-forgot:first-child {
    margin-top: 10px
}

.login__text-forgot {
    font-size: 14px;
    color: #103173;
    text-align: center;
    margin-bottom: 0
}

.login__text-forgot a {
    text-decoration: underline;
    color: #103173
}

.login__text-forgot a:hover {
    color: #103173;
    text-decoration: none
}

.sign-agree .chekcbox-wrp {
    width: 100%;
    margin-top: 0
}

.links-login {
    display: inline-block
}

.price {
    min-height: calc(100vh - 98px);
    padding-bottom: 50px
}

.price__title {
    color: #103173;
    margin-top: 20px;
    font-size: 36px;
    text-align: center
}

.price__card {
    margin-top: 40px;
    max-width: 450px;
    width: 100%;
    background: #d9e4f6;
    margin-right: auto;
    margin-left: auto;
    padding: 15px;
    padding-top: 30px;
    padding-bottom: 30px
}

.price__card-title {
    color: #103173;
    text-align: center;
    font-size: 26px;
    font-weight: bold
}

.price__card-title span {
    text-transform: uppercase
}

.price__list {
    list-style: none;
    margin-bottom: 0;
    padding: 0;
    min-height: 82px;
    margin-bottom: 30px
}

.price__list li {
    padding-left: 20px;
    margin-left: 30px;
    position: relative;
    margin-bottom: 5px
}

.price__list li:after {
    content: "\2713";
    color: #103173;
    position: absolute;
    top: 0;
    left: 0
}

.message {
    min-height: 200px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding-right: 50px;
    padding-left: 50px
}

.login_message {
    background: #fafafa
}

.login_message .login__buttons {
    margin-top: 20px
}

.page-404__title {
    font-size: 180px;
    text-align: center;
    color: #3174DD;
    font-weight: bold
}

.page-404__text {
    text-align: center;
    font-size: 20px;
    color: #103173;
    margin-bottom: 10px
}

.page-404 .login__buttons {
    margin-top: 90px;
    margin-bottom: 60px
}

.police {
    margin-top: 30px;
    padding-bottom: 20px
}

.police__text-title {
    padding-left: 20px
}

.police__text {
    padding-left: 20px
}

.account {
    min-height: calc(100vh - 275px)
}

.account__free {
    position: relative;
    top: -5px;
    margin-top: 0 !important
}

.account__card {
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 1000px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    background: #d9e4f6;
    padding: 20px;
    padding-bottom: 60px;
    color: #103173 !important
}

.account__block {
    margin-bottom: 15px
}

.account__block .account__line-wrp .general__btn {
    margin-top: 10px
}

.account__block .general__btn {
    display: inline-block;
    margin-top: 20px
}

.account__title {
    margin-bottom: 15px
}

.account__line-wrp {
    width: 100%;
    padding-left: 20px;
    font-size: 16px !important
}

.account__value {
    font-weight: bold;
    margin-bottom: 5px;
    display: inline-block
}

.account__value .value {
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    font-weight: normal
}

.account__value .value-card {
    margin-right: 5px;
    margin-left: 5
}

.account__link-change {
    height: 20px;
    width: 20px
}

.account__link-change:hover {
    cursor: pointer
}

.account__link-change img {
    width: 14px;
    height: 14px
}

.modal_account .form-control {
    margin-bottom: 15px
}

.modal_account .modal-body {
    padding-right: 50px;
    padding-left: 50px
}

.modal_account#modalName .modal-body {
    padding-right: 15px;
    padding-left: 15px
}

.modal_account .modal-dialog {
    max-width: 400px
}

.modal_account .login__buttons {
    margin-top: 20px !important
}

.modal_account .login__title {
    margin-bottom: 15px
}

.modal__wrp-input .message-send {
    display: none;
    font-size: 14px;
    color: #78BE5D
}

.modal__wrp-input.send .form-control {
    margin-bottom: 0px
}

.modal__wrp-input.send .message-send {
    display: inline-block
}

.footer__links {
    text-align: right
}

.footer__wrp {
    background: #3174dd;
    padding-top: 3px;
    padding-bottom: 3px
}

.footer__wrp a {
    color: #fff
}

.footer__wrp a:hover {
    color: #fff
}

.list-inline-item {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-right: 0 !important
}

.list-inline {
    margin-bottom: 0
}

.list-inline-item:not(:last-child) {
    border-right: 1px solid #fff
}

.closebtn, .mobile-menu, .openbtn {
    display: none
}

.popup__text {
    margin-bottom: 15px !important;
    color: #103173;
    text-align: center;
    margin-bottom: 0;
    font-weight: bold;
    font-size: 18px
}

.popup .login__buttons {
    margin-top: 30px;
    margin-bottom: 10px
}

.popup .login__buttons .login__btn {
    min-width: 200px;
    text-align: center
}

.modal-signup .modal-header {
    border-bottom: 0;
    padding-top: 0;
    padding-bottom: 0
}

.modal-signup .modal-header .close {
    margin-top: 0
}

.modal-signup .modal-content {
    border-radius: 0
}

.navbar__links .dropdown-menu_custom {
    padding: 0;
    border: 1px solid #A4A5A9;
    min-width: 160px
}

.navbar__links .dropdown-menu_custom .login__btn {
    -webkit-transition: 0.2s all;
    -o-transition: 0.2s all;
    transition: 0.2s all;
    padding-bottom: 0;
    margin-bottom: 0;
    height: 36px;
    background: #fff;
    color: #103173
}

.navbar__links .dropdown-menu_custom .login__btn:hover {
    background: #579AFF;
    color: #fff
}

.navbar__links .dropdown-menu_custom .login__btn:active {
    color: #fff
}

.navbar__links .dropdown-menu_custom .login__btn:first-child {
    border-bottom: 1px solid #A4A5A9
}

@media screen and (max-width: 1199px) {
    .general__tab-content {
        overflow-x: scroll;
        white-space: nowrap
    }

    .general__tab-content .item {
        display: inline-block
    }

    .general__tab-content .table {
        width: 825px
    }

    .general__nav {
        border: 0 !important
    }

    .general__nav .nav-item .dropdown-item.active {
        color: #fff !important
    }

    .general__nav .nav-item .dropdown-item.active span {
        background: #fff;
        color: #000
    }

    .general__nav .nav-item {
        display: none
    }

    .general__nav .nav-item.dropdown {
        display: block;
        color: #495057 !important;
        border: 0 !important;
        border-radius: 6px;
        margin-left: 2px
    }

    .general__nav .nav-item.dropdown a {
        color: #495057 !important;
        border: 0 !important
    }

    .estimation__card_first {
        padding-right: 200px;
        padding-left: 200px
    }

    .card__text-text .title {
        width: 55px
    }
}

@media screen and (max-width: 991px) {
    .sidenav {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 999;
        top: 0;
        left: 0;
        overflow-x: hidden;
        -webkit-transition: 0.5s;
        -o-transition: 0.5s;
        transition: 0.5s
    }

    .mobile-menu {
        width: 100%;
        height: 40px;
        position: fixed;
        background: #043d7b94;
        display: block;
        z-index: 99
    }

    .general__navbar {
        margin-top: 0;
        margin-bottom: 0
    }

    .closebtn {
        display: inline-block;
        color: #fff;
        font-size: 30px;
        position: relative;
        top: -10px
    }

    .closebtn:hover {
        text-decoration: none;
        color: #fff
    }

    .openbtn {
        display: inline-block;
        margin-left: 10px;
        color: #fff !important;
        font-size: 30px;
        position: relative;
        top: -4px
    }

    .openbtn:hover {
        text-decoration: none;
        color: #fff
    }

    .general__forms {
        margin-top: 90px;
        margin-right: auto;
        margin-left: auto
    }

    .general__tab-content .container {
        margin-right: 0;
        margin-left: 0
    }

    .navbar__links {
        width: calc(100% - 200px)
    }

    .link-menu {
        padding-left: 20px;
        width: calc(100% - 40px);
        display: block;
        float: left;
        margin-right: 0 !important
    }

    .search__title {
        margin-top: 20px
    }

    .general__content {
        background-position: 300% 100%;
        padding-bottom: 50px;
        -webkit-background-size: 100% 100%;
        background-size: 100%
    }

    .nav__message-wrp {
        margin-bottom: 10px
    }

    .bread-crumbs {
        margin-bottom: 10px
    }

    .page-404 .login__buttons {
        margin-top: 30px;
        margin-bottom: 30px
    }

    .links-login {
        padding-top: 10px
    }

    .nav__wrp-menu_btns .link-menu {
        width: calc(100% - 160px)
    }
}

@media screen and (max-width: 767px) {
    .navbar__links {
        width: 100%
    }

    .link-menu {
        padding-left: 0;
        width: calc(100% - 40px)
    }

    .general__content {
        background: #fafafa
    }

    .search__title {
        margin-left: 0
    }

    .card-result__text {
        display: inline-block;
        margin-bottom: 0
    }

    .card__text-text {
        margin-bottom: 10px
    }

    .estimation__card_first {
        padding-right: 15px;
        padding-left: 15px
    }

    .estimation__top {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .estimation__top-title {
        margin-top: 10px;
        text-align: center
    }

    .nav__padding {
        height: 115px
    }

    .card__text-group {
        width: 100%;
        position: relative;
        top: 0;
        right: 0
    }

    .card-result__text {
        width: 100%
    }

    .title-price {
        margin-bottom: 6px
    }

    .links-login {
        padding-left: 0
    }
}

@media screen and (max-width: 575px) {
    .btn__plus {
        margin-top: 20px
    }

    .general__text-form {
        margin-bottom: 0
    }

    .general__forms .form-group {
        margin-bottom: 5px
    }

    .nav__message-wrp {
        padding-right: 10%;
        padding-left: 10%
    }

    .nav__message-wrp {
        padding-bottom: 20px;
        border-radius: 0 0 100% 100% / 0 0 50% 50%
    }

    .chekcbox-wrp img {
        margin-right: 5px;
        margin-left: 5px;
        fill: #103173
    }

    .modal_account .modal-dialog {
        margin-right: auto;
        margin-left: auto
    }
}

@media screen and (max-width: 480px) {
    .general__title {
        font-size: 30px
    }

    .general__top {
        margin-left: 20px
    }

    .list-inline-item {
        width: 100%;
        text-align: center;
        border: 0 !important;
        padding-top: 2px;
        padding-bottom: 2px
    }

    .footer__links {
        padding-top: 5px;
        padding-bottom: 5px
    }

    .card__text-group {
        margin-top: 10px;
        width: 100%
    }

    .price__list li {
        margin-left: 0
    }

    .page-404__title {
        font-size: 140px
    }

    .links-login {
        position: absolute;
        top: 0;
        right: 0;
        width: 80px
    }

    .links-login a.login__btn {
        margin-bottom: 5px;
        width: 100%;
        display: inline-block;
        font-size: 14px;
        text-align: center
    }

    .nav__wrp-menu_btns .link-menu {
        width: 100%
    }
}

@media screen and (max-width: 400px) {
    .navbar__links a.link-menu {
        padding-right: 40px
    }

    .nav__padding {
        height: 139px
    }

    .message {
        padding-right: 15px;
        padding-left: 15px
    }

    .police {
        margin-top: 20px
    }

    .police__text-title {
        padding-left: 10px
    }

    .police__text {
        padding-left: 10px
    }

    .modal_account .modal-dialog {
        margin-right: 15px;
        margin-left: 15px
    }

    .modal_account .modal-body {
        padding-right: 15px;
        padding-left: 15px
    }
}

@media screen and (max-width: 360px) {
    .navbar__links a.link-menu {
        padding-right: 0
    }

    .icon-eco {
        top: 10px;
        right: 10px;
        width: 20px
    }

    .chekcbox-wrp img {
        width: 20px
    }
}