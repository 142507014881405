.nav__row {
    max-height: initial;
    overflow-y: hidden;
    overflow-x: hidden;
}

.form-group.error .error-text {
    position: initial;
    bottom: initial;
}

.estimation__card_2 .estimation__input-text_money {
    width: initial;
}

.login {
    background: none !important;
}

.login-card {
    max-width: 550px;
}

.login__text-input{
    margin-top: 8px;
    margin-bottom: 10px;
}

.promo-label{
    font-size: 0.75rem;
    margin-top: 4px;
    margin-bottom: 0;
}

.promo-input{
    width: 30%;
    height: 10%;
}